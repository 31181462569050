import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchAssessmentTypes,
  assignInventionExpert
} from 'actions/inventionAssessmentActions';

import { fetchOrganisationUsers } from 'actions/organisationActions';
import { getProposedExperts } from 'store/singleInvention/singleInventionSelectors';
import {
  getAreUsersLoading,
  getUsersForOptions
} from 'store/users/usersSelectors';
import {
  getAssessmentTemplates,
  getIsFetchingTemplates
} from 'store/inventionAssessments/inventionAssessmentsSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

import { Action, PersonWrapper, IconWrapper, ProposalWrapper } from './styles';
import Spinner from 'Atoms/Spinner';

import { assessmentVisibilitySelectOptions } from 'constants/createFormTypeFieldsConstants';

const ExpertsModal = ({
  allowAssessmentVisibility,
  areUsersLoading,
  assessmentTemplates,
  assessmentVisibility,
  assignExpert,
  closeModal,
  experts,
  fetchAssessmentTypes,
  getOrganisationUsers,
  inventionId,
  isFetchingTemplates,
  isOpen,
  proposedUsers
}) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedExpert, setSelectedExpert] = useState();
  const [selectedAssessmentVisibility, setSelectedAssessmentVisibility] =
    useState(assessmentVisibility);

  useEffect(() => {
    if (assessmentTemplates.length === 1) {
      setSelectedTemplate(assessmentTemplates[0]);
    }
  }, [assessmentTemplates]);

  const renderProposedUsers = user => (
    <PersonWrapper>
      <IconWrapper>
        <FontAwesomeIcon icon={faUserCircle} />
      </IconWrapper>
      <span>{`${user.first_name} ${user.last_name}`}</span>
    </PersonWrapper>
  );

  const handleAssignExpert = () => {
    const data = {
      data: {
        type: 'assessment',
        attributes: {
          status: 1,
          expert: {
            id: selectedExpert,
            type: 'user'
          },
          assessment_type: {
            id: selectedTemplate.value,
            type: 'assessment_type'
          },
          invention: {
            id: `/v1/inventions/${inventionId}`,
            type: 'invention'
          },
          assessment_visibility: selectedAssessmentVisibility
        }
      }
    };
    assignExpert(data);
    closeModal();
  };

  useEffect(() => {
    getOrganisationUsers();
    fetchAssessmentTypes();
    // eslint-disable-next-line
  }, []);

  const parsedAssessmentVisibilityOptions =
    assessmentVisibilitySelectOptions.map(option => ({
      ...option,
      label: t(option.label)
    }));

  const content = () => (
    <Fragment>
      {!isFetchingTemplates ? (
        <>
          <ProposalWrapper>
            {proposedUsers.length > 0 && (
              <>
                {t('inventorProposition')}:
                {proposedUsers.map(el =>
                  renderProposedUsers(el.data.attributes)
                )}
              </>
            )}
          </ProposalWrapper>

          <Action>
            <label className="form-check-label">
              {t('selectAssessmentType')}
            </label>
            <Select
              options={assessmentTemplates}
              placeholder={`${t('select')}...`}
              value={selectedTemplate || null}
              onChange={e => setSelectedTemplate(e)}
            />
          </Action>
          <Action>
            <label className="form-check-label">{t('chooseExpert')}</label>
            <Select
              isLoading={areUsersLoading}
              options={experts}
              placeholder={`${t('select')}...`}
              onChange={e => setSelectedExpert(e.value)}
            />
          </Action>
          {allowAssessmentVisibility && (
            <Action>
              <label className="form-check-label">
                {t('selectAssessmentVisibility')}
              </label>
              <Select
                value={
                  parsedAssessmentVisibilityOptions.find(
                    option => option.value === selectedAssessmentVisibility
                  ) || parsedAssessmentVisibilityOptions[0]
                }
                options={parsedAssessmentVisibilityOptions}
                onChange={e => setSelectedAssessmentVisibility(e.value)}
              />
            </Action>
          )}
        </>
      ) : (
        <Spinner center />
      )}
    </Fragment>
  );

  const footer = () => (
    <Fragment>
      <Button value={t('close')} onClick={closeModal} />
      <Button
        colorVariant="green"
        disabled={!(selectedTemplate && selectedExpert)}
        value={t('add')}
        onClick={handleAssignExpert}
      />
    </Fragment>
  );

  return (
    <Modal
      content={content()}
      footer={footer()}
      isOpen={isOpen}
      title={t('assignExperts')}
      onHide={closeModal}
    />
  );
};

const mapStateToProps = state => ({
  experts: getUsersForOptions(state, 'experts'),
  areUsersLoading: getAreUsersLoading(state),
  assessmentTemplates: getAssessmentTemplates(state),
  isFetchingTemplates: getIsFetchingTemplates(state),
  proposedUsers: getProposedExperts(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrganisationUsers: fetchOrganisationUsers,
      fetchAssessmentTypes: fetchAssessmentTypes.trigger,
      assignExpert: assignInventionExpert.trigger
    },
    dispatch
  );

ExpertsModal.propTypes = {
  allowAssessmentVisibility: PropTypes.bool,
  areUsersLoading: PropTypes.bool,
  assessmentTemplates: PropTypes.arrayOf(PropTypes.object),
  assessmentVisibility: PropTypes.string,
  assignExpert: PropTypes.func,
  closeModal: PropTypes.func,
  experts: PropTypes.arrayOf(PropTypes.object),
  fetchAssessmentTypes: PropTypes.func,
  getOrganisationUsers: PropTypes.func,
  inventionId: PropTypes.number,
  isFetchingTemplates: PropTypes.bool,
  isOpen: PropTypes.bool,
  proposedUsers: PropTypes.arrayOf(PropTypes.object)
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsModal);
