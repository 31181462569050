import React from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import {
  FormField,
  FormName,
  Label,
  DescriptionWrapper,
  LockContainer
} from './styles';

import AddOrEditInventionTypeModal from 'Elements/AddOrEditInventionTypeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faLock } from '@fortawesome/free-solid-svg-icons';
import { createFormTypeFields } from 'constants/createFormTypeFieldsConstants';
import { INVENTION_TYPE } from 'constants/formTypesConstants';

class FormTypeDescription extends React.Component {
  state = {
    showModal: false
  };

  displayModal = value => {
    this.setState({
      showModal: value
    });
  };

  getFormElementValue = ({ label, value }) => {
    if (label === 'allowCoinventors' || label === 'allowAssessmentVisibility') {
      return value ? t('yes') : t('no');
    }

    if (label === 'defaultAssessmentVisibility') {
      return value === 'private' ? t('private') : t('public');
    }

    if (label.includes('Deadline')) {
      return `${value} ${value === 1 ? t('day') : t('days')}`;
    }

    return value;
  };

  render() {
    const { formTypeId, formTypeMetaData, isFormTypeLocked, isLoading } =
      this.props;

    return (
      <>
        {isFormTypeLocked && !isLoading && (
          <LockContainer>
            <FontAwesomeIcon icon={faLock} />
            <span>{t('lockedType')}</span>
          </LockContainer>
        )}
        <DescriptionWrapper>
          {!isFormTypeLocked && (
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => this.displayModal(true)}
            />
          )}
          {formTypeMetaData.map(el => {
            const fieldValues = createFormTypeFields[Object.keys(el)[0]];
            const { label } = fieldValues;
            const value = el[label === 'defaultDeadline' ? 'deadline' : label];

            return (
              <React.Fragment key={label}>
                {label === 'name' ? (
                  <FormName>
                    <Label>
                      {t(label)}: {value}
                    </Label>
                  </FormName>
                ) : (
                  <FormField key={label}>
                    <Label> {t(label)}</Label>:{' '}
                    {this.getFormElementValue({ label, value })}
                  </FormField>
                )}
              </React.Fragment>
            );
          })}

          {this.state.showModal && (
            <AddOrEditInventionTypeModal
              closeModal={this.displayModal}
              editFormTypeMetaData={this.props.editFormTypeMetaData}
              fields={this.props.formTypeMetaData}
              formTypeId={formTypeId}
              isOpen={this.state.showModal}
              type={INVENTION_TYPE}
              isEdited
            />
          )}
        </DescriptionWrapper>
      </>
    );
  }
}

export default withTranslation('iamip')(FormTypeDescription);
