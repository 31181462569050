import React, { useState, useEffect } from 'react';
import DefaultTabs from 'Atoms/Tabs';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { getSingleInventionForm } from 'store/singleInvention/singleInventionSelectors';

import { prepareSectionObjects } from 'helpers/sectionHelpers';

import { InventionPreviewField } from './InventionPreviewField';
import {
  FieldsContainer,
  FieldName,
  FieldDescription,
  ButtonsWrapper
} from './styles';
import TabsButtons from 'Elements/TabsButtons';

import { INFORMATION_FIELD_ID } from 'constants/formTypeFieldConstants';

const InventionFormPreview = ({
  formData,
  inReview,
  reviewData,
  handleAddComment,
  handleRemoveComment,
  surveyReviewId,
  isInSinglePage,
  sectionIndex,
  setSectionIndex
}) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  useEffect(() => {
    setSectionIndex && setSectionIndex(currentSectionIndex);
    // eslint-disable-next-line
  }, [currentSectionIndex]);

  useEffect(() => {
    setCurrentSectionIndex(sectionIndex);
  }, [sectionIndex]);

  const sections =
    (!isEmpty(formData) && formData.form.data.attributes.sections) || [];

  const answers = !isEmpty(formData) && formData.answers;
  const renderTabContent = id => {
    const sectionToRender = sections.find(el => el.data.attributes.id === id);

    return (
      <FieldsContainer>
        {sectionToRender &&
          sectionToRender.data.attributes.fields.map(el => {
            const fieldAnswer = answers.find(
              answer => answer.data.attributes.field.data.id === el.data.id
            );
            const review =
              reviewData && reviewData.data && inReview && fieldAnswer
                ? reviewData.data.attributes.answer_reviews.find(review => {
                    const answerId = review.attributes.answer.data
                      ? review.attributes.answer.data.id
                      : review.attributes.answer.id;

                    return answerId === fieldAnswer.data.id;
                  })
                : null;

            return (
              <React.Fragment key={el.data.id}>
                <FieldName>{el.data.attributes.name}</FieldName>
                {el.data.attributes.type !== INFORMATION_FIELD_ID && (
                  <FieldDescription>
                    {el.data.attributes.description}
                  </FieldDescription>
                )}

                <InventionPreviewField
                  answer={fieldAnswer ? fieldAnswer.data.attributes : ''}
                  answerId={fieldAnswer ? fieldAnswer.data.id : ''}
                  field={el.data}
                  handleAddComment={inReview ? handleAddComment : undefined}
                  handleRemoveComment={handleRemoveComment}
                  inReview={inReview}
                  isInSinglePage={isInSinglePage}
                  review={inReview && review ? review : undefined}
                  surveyReviewId={surveyReviewId}
                />
              </React.Fragment>
            );
          })}
        {sections.length > 1 && !inReview && (
          <ButtonsWrapper>
            <TabsButtons
              currentIndex={currentSectionIndex}
              sectionsLength={sections.length - 1}
              onTabChange={setCurrentSectionIndex}
            />
          </ButtonsWrapper>
        )}
      </FieldsContainer>
    );
  };

  return (
    <DefaultTabs
      content={renderTabContent}
      currentSectionIndex={currentSectionIndex}
      data={prepareSectionObjects(sections)}
      setCurrentSectionIndex={setCurrentSectionIndex}
    />
  );
};

const mapStateToProps = state => ({ formData: getSingleInventionForm(state) });

export default connect(mapStateToProps)(InventionFormPreview);
