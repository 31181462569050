export const assessmentVisibilitySelectOptions = [
  { value: 'private', label: 'private' },
  { value: 'public', label: 'public' }
];

export const createFormTypeFields = {
  name: { key: 'name', label: 'name', type: 'input' },
  description: { key: 'description', label: 'description', type: 'text' },
  preAssessmentDeadline: {
    key: 'pre_assessment_deadline',
    label: 'preAssessmentDeadline',
    type: 'number',
    value: 14
  },
  expertAssessmentDeadline: {
    key: 'expert_assessment_deadline',
    label: 'expertAssessmentDeadline',
    type: 'number',
    value: 14
  },
  decisionDeadline: {
    key: 'decision_deadline',
    label: 'decisionDeadline',
    type: 'number',
    value: 14
  },
  allowCoinventors: {
    key: 'allow_coinventors',
    label: 'allowCoinventors',
    type: 'checkbox'
  },
  deadline: {
    key: 'deadline',
    label: 'defaultDeadline',
    type: 'number',
    value: 14
  },
  serialNumber: {
    key: 'serial_number',
    label: 'serialNumber',
    type: 'fixed'
  },
  defaultAssessmentVisibility: {
    key: 'assessment_visibility',
    label: 'defaultAssessmentVisibility',
    type: 'select',
    options: [...assessmentVisibilitySelectOptions]
  },
  allowAssessmentVisibility: {
    key: 'allow_assessment_visibility',
    label: 'allowAssessmentVisibility',
    type: 'checkbox'
  }
};

export const SERIAL_NUMBER = 'serial_number';
export const DELIMITER = 'delimiter';
