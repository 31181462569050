import { createSelector } from 'reselect';

export const getInventionTypeMetaData = createSelector(
  state => state.formType.data.attributes,
  attributes => [
    { name: attributes.name },
    {
      description: attributes.description
    },
    {
      preAssessmentDeadline: attributes.pre_assessment_deadline
    },
    {
      expertAssessmentDeadline: attributes.expert_assessment_deadline
    },
    {
      decisionDeadline: attributes.decision_deadline
    },
    {
      allowCoinventors: attributes.allow_coinventors
    },
    {
      allowAssessmentVisibility: attributes.allow_assessment_visibility
    },
    {
      defaultAssessmentVisibility: attributes.assessment_visibility
    },
    {
      serialNumber: attributes.number_template_example
    }
  ]
);
