import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePageLayout from 'Elements/TablePageLayout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { withRouter, useHistory } from 'react-router-dom';

import { STATUS_ASSESSMENT } from 'constants/inventionStatuses';

import Button from 'Atoms/Button';
import { INVENTIONS_PAGE, ASSESSMENTS_PAGE } from 'constants/routingConstants';
import usePrevious from 'helpers/hooks/usePrevious';

import { fetchInventionAssessments } from 'actions/inventionAssessmentActions';

import {
  getIsFetching,
  getInventionAssessments,
  getIsExpertAssigning
} from 'store/inventionAssessments/inventionAssessmentsSelectors';
import PropTypes from 'prop-types';
import ExpertsModal from 'Elements/ExpertsModal';
import {
  getSurveyId,
  getCurrentInventionId,
  getCurrentInventionStatus,
  getReviewManager,
  getCurrentInventionAllowAssessmentVisibility,
  getCurrentInventionAssessmentVisibility
} from 'store/singleInvention/singleInventionSelectors';
import {
  getCurrentUserRoles,
  getCurrentUserId
} from 'store/users/usersSelectors';
import { EXPERT, REVIEW_MANAGER } from 'constants/userRoles';
import {
  STATUS_PENDING,
  STATUS_IN_PROGRESS,
  STATUS_REVIEW,
  assessmentStatuses
} from 'constants/assessmentStatuses';

import { fetchSingleInvention } from 'actions/singleInventionActions';

import MoveToDecisionModal from 'Pages/SingleInventionPage/Modals/MoveToDecisionModal';

import { formatDate } from 'helpers/dateHelpers';

const InventionAssessmentsListPage = ({
  allowAssessmentVisibility,
  assessments,
  assessmentVisibility,
  currentInventionStatus,
  currentUserId,
  currentUserRoles,
  fetchInvention,
  fetchInventionAssessments,
  isExpertAssigning,
  isFetching,
  match,
  reviewManager
}) => {
  const [displayModal, setDisplayModal] = useState();
  const inventionId = parseInt(match.params.id);
  const { t } = useTranslation();
  const history = useHistory();

  const columnNames = [
    t('assessmentId'),
    t('expertName'),
    t('assessmentType'),
    t('status'),
    t('deadline'),
    t('actions')
  ];

  const MODALS = {
    assessment: 'assessment',
    decision: 'decision'
  };

  const handleChangePage = page =>
    fetchInventionAssessments({
      id: inventionId,
      page: page + 1
    });

  const inventionAssessmentListMeta =
    assessments && assessments.meta ? assessments.meta : {};

  const previousIsExpertAssigning = usePrevious(isExpertAssigning);
  const reviewManagerId = reviewManager && reviewManager.id;
  const isCurrentUserAReviewManager =
    reviewManagerId && reviewManagerId === currentUserId;

  const closeModal = () => setDisplayModal(null);

  const buttons = [
    {
      label: t('assignNewAssessment'),
      action: () => setDisplayModal(MODALS.assessment)
    },
    {
      label: t('moveForDecision'),
      action: () => setDisplayModal(MODALS.decision)
    }
  ];

  const prepareActions = (
    inventionId,
    assessmentId,
    status,
    assignedExpert
  ) => {
    let value = t('preview');
    let color = 'blue';

    if (currentUserRoles.includes(EXPERT) && assignedExpert === currentUserId) {
      value =
        status === STATUS_PENDING
          ? t('complete')
          : status === STATUS_IN_PROGRESS
          ? t('continue')
          : value;
      color =
        status === STATUS_IN_PROGRESS || status === STATUS_PENDING
          ? 'green'
          : color;
    } else if (currentUserRoles.includes(REVIEW_MANAGER)) {
      value = status === STATUS_REVIEW ? t('review') : value;
    }

    return (
      <Button
        colorVariant={color}
        value={value}
        small
        onClick={() =>
          history.push(
            `${INVENTIONS_PAGE}/${inventionId}${ASSESSMENTS_PAGE}/${assessmentId}`
          )
        }
      />
    );
  };

  const rows =
    assessments && assessments.data
      ? assessments.data.map(el => {
          const id = el.id && el.id.split('/')[el.id.split('/').length - 1];
          const inventionId = el.attributes.invention.data.attributes.id;

          return {
            id: id,
            expert: `${el.attributes.expert.data.attributes.first_name} ${el.attributes.expert.data.attributes.last_name}`,
            assessmentType:
              el.attributes.survey.data.attributes.form.data.attributes.name,
            status: assessmentStatuses(t).find(
              status => status.id === el.attributes.status
            ).label,
            deadline: formatDate(el.attributes.deadline),
            actions: () =>
              prepareActions(
                inventionId,
                id,
                el.attributes.status,
                el.attributes.expert.id
              )
          };
        })
      : [];

  useEffect(() => {
    fetchInventionAssessments({ id: inventionId });
    fetchInvention(inventionId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      previousIsExpertAssigning &&
      !isExpertAssigning &&
      inventionAssessmentListMeta.currentPage === 1
    ) {
      fetchInventionAssessments({ id: inventionId });
    }
    // eslint-disable-next-line
  }, [isExpertAssigning]);

  return (
    <>
      <TablePageLayout
        buttons={
          currentInventionStatus <= STATUS_ASSESSMENT &&
          isCurrentUserAReviewManager
            ? buttons
            : []
        }
        buttonLabel={t('assignNewAssessment')}
        columnNames={columnNames}
        data={rows}
        formTypeListMeta={inventionAssessmentListMeta}
        handleChangePage={handleChangePage}
        isFetching={isFetching}
      />
      {displayModal === MODALS.assessment && (
        <ExpertsModal
          allowAssessmentVisibility={allowAssessmentVisibility}
          assessmentVisibility={assessmentVisibility}
          closeModal={closeModal}
          inventionId={inventionId}
          isOpen
        />
      )}
      {displayModal === MODALS.decision && (
        <MoveToDecisionModal
          isOpen={displayModal === MODALS.decision}
          onHide={closeModal}
        />
      )}
    </>
  );
};

const mapStateToProps = createPropsSelector({
  isFetching: getIsFetching,
  assessments: getInventionAssessments,
  surveyId: getSurveyId,
  isExpertAssigning: getIsExpertAssigning,
  currentUserRoles: getCurrentUserRoles,
  currentUserId: getCurrentUserId,
  currentInventionId: getCurrentInventionId,
  currentInventionStatus: getCurrentInventionStatus,
  reviewManager: getReviewManager,
  allowAssessmentVisibility: getCurrentInventionAllowAssessmentVisibility,
  assessmentVisibility: getCurrentInventionAssessmentVisibility
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventionAssessments,
      fetchInvention: fetchSingleInvention.trigger
    },
    dispatch
  );

InventionAssessmentsListPage.propTypes = {
  allowAssessmentVisibility: PropTypes.bool,
  assessmentVisibility: PropTypes.string,
  assessments: PropTypes.object,
  currentInventionStatus: PropTypes.number,
  currentUserId: PropTypes.string,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  fetchInvention: PropTypes.func,
  fetchInventionAssessments: PropTypes.func,
  isExpertAssigning: PropTypes.bool,
  isFetching: PropTypes.bool,
  match: PropTypes.object,
  reviewManager: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InventionAssessmentsListPage));
